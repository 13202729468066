import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "../assets/css/global.css"
import Instagram from "../assets/images/instagram.svg"
import Facebook from "../assets/images/facebook.svg"
import LinkedIn from "../assets/images/linkedin.svg"
import Twitter from "../assets/images/twitter.svg"

const SponsorsList = ({ sponsors = [] }) => {
  return (
    <div>
      {sponsors.map(sponsor => {
        const { id, aboutText, sponsorName, sponsorLogo, sponsorWebsite, instagramLink, facebookLink, linkedinLink, twitterLink } = sponsor
        console.log(sponsorLogo)
        return (
          <div className="grid-cols-1" style={{alignItems: "center", textAlign: "center"}}>
                <div className="col-span-1">
                  <div key={id} className="container pb-50" style={{backgroundColor: "#000", paddingLeft: "5rem", paddingRight: "5rem", paddingTop: "50px", marginBottom: "2rem"}}>
                    <GatsbyImage
                        image={sponsorLogo.gatsbyImageData}
                        className="margin-auto"
                        />
                  </div>
                  <div className="col-span-3 margin-auto">
                    <div className="icon-center">
                      {(instagramLink) ? <a href={ instagramLink } target="_blank" rel="noreferrer">
                        <img
                          src={Instagram}
                          alt="Instagram icon"
                          className="hero-img"
                          placeholder="tracedSVG"
                          style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px", paddingRight: "15px"}}
                        />
                      </a> : null}
                      {(facebookLink) ? <a href={ facebookLink } target="_blank" rel="noreferrer">
                        <img
                          src={Facebook}
                          alt="Facebook icon"
                          className="hero-img"
                          placeholder="tracedSVG"
                          style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px", paddingRight: "15px"}}
                        />
                      </a> : null}
                      {(linkedinLink) ? <a href={ linkedinLink } target="_blank" rel="noreferrer">
                        <img
                          src={LinkedIn}
                          alt="LinkedIn icon"
                          className="hero-img"
                          placeholder="tracedSVG"
                          style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px", paddingRight: "15px"}}
                        />
                      </a> : null}
                      {(twitterLink) ? <a href={ twitterLink } target="_blank" rel="noreferrer">
                        <img
                          src={Twitter}
                          alt="Twitter icon"
                          className="hero-img"
                          placeholder="tracedSVG"
                          style={{maxWidth: "10%", display: "inline-block", fontSize: "10px", paddingBottom: "15px"}}
                        />
                      </a> : null}
                    </div>
                  </div>
                  <p style={{textAlign: "left", paddingBottom: "1rem"}}>{aboutText}</p>
                  <div style={{textAlign: "left", paddingBottom: "1rem"}}><a href={`${sponsorWebsite}`} target="_blank" rel="noopener">Explore {sponsorName} &rarr;</a></div>
                </div>
                </div>
        )
      })}
    </div>
  )
}

export default SponsorsList
