import React from "react"
import SponsorsList from "./SponsorsList.js"
import { graphql, useStaticQuery } from "gatsby"
import "../assets/css/global.css"

const query = graphql`
  {
    allDatoCmsSponsor {
        nodes {
          id
          sponsorName
          aboutText
          sponsorLogo {
            gatsbyImageData
            alt
          }
          sponsorWebsite
          instagramLink
          facebookLink
          linkedinLink
          twitterLink
        }
      }
  }
`

const AllSponsors = () => {
  const data = useStaticQuery(query)
  const sponsors = data.allDatoCmsSponsor.nodes
  return (
    <section className="container-lg">
      <SponsorsList sponsors={sponsors} />
    </section>
  )
}

export default AllSponsors
